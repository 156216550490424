/* crp.module.css */



.MainpartSetup {
    background-color: #F2F2F2;
    padding-top: 4.8em;
}
.viewActivityDiv {
    text-align: end;
    margin-top: 0.5em;
    margin-bottom: 1em;   
}
.viewActivitiesLabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}
.viewActivitiesLabel label {
    text-decoration: underline !important;
}
.viewActivitiesLabel:hover {
    text-decoration: underline !important;
    font-weight: 800;
    color: #2E637D;
}
.ActivityTableColumn {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
}
.crprogramDiv {
    padding: 15px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;   
}
.ActiviteIdDiv {
    padding: 15px;
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    margin-bottom: 1em;   
}
.headerTextTemplate {
    color: #40637D;
    text-align: start;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.56px;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}
.ProgramTextStyle {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.ProgramTextStyleBold {
    color: var(#000, #737373);
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
}
.programTextStyleTCs {
    color: #737373 !important;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.programTextStyleTCs:hover {
    color:#000 !important;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-decoration: underline;
}
.labelTextStyle {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: 0em;
    margin-bottom: 1em;
}
.ActivityLabelTextStyle {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-top: 0em;
}
.ShareOptionDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 5px;
}
.sharewithSocialText {
    color: #40637D;
    text-align: center;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 0% */
    letter-spacing: -0.32px;
    margin-top: 1em;
    margin-bottom: 0em;
}
.invitewithMailBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    padding: 4px 12px;
    width: 100%;
    background: #2E637D;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom: 5px;
}
.invitewithMailBtn:hover {
    border-radius: 2px;
    background: #2E637D;
}
.promoCodeActivateBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    padding: 10px 20px;
    width: 100%;
    background: #2E637D;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}
.promoCodeActivateBtn:hover {
    border-radius: 5px;
    padding: 10px 20px;
    width: 100%;
    background: #40637D;
    color: var(--Generic-White, #FFF);
    font-size: 18px;
}
.shareonSocialplots {
    padding: 5px 10px;
    border-radius: 6px;
    background: #2E637D;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 150% */
}

.shareCopyText {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
}
.URLlinkshow {
    color: #2f48fb !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0.5em;
    margin-bottom: 1em;
    background: none;
    border: none;
    outline: none;
    opacity: 1 !important;
}
.URLlinkshow:disabled {
    color: inherit;
    text-decoration: none;
    cursor: no-drop;
    pointer-events: none;
}
.ClickCopyBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 6px;
    border-radius: 6px;
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.ClickCopyBtn:hover {
    background: #2E637D;
    border: none;
    color: var(--Generic-White, #FFF);
}

.ItemDataBox {
    background: #40637D;
    border-radius: 12px;
    box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
    padding: 10px;
}
.itemlabelText {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    white-space: normal;
}
.TitleIcondiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
}
.IconfaStyles {
    width: 26px;
    height: 26px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 3px solid #FACF39;
    padding: 5px;
}
.ItemCountdiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
}
.ItemDataCount {
    color: #FACF39;
    font-family: "Josefin Sans";
    font-size: 35px;
    text-align: start;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}
.tabsPadding {
    padding: 10px;
}
.userInviteDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    overflow: auto;
    overflow-x: scroll;
    width: 155px;
    white-space: nowrap;
    scrollbar-width: none;
}
.userinviteData {
    cursor: pointer;
    text-align: start;
}
.inviteUserImg {
    width: 40px;
    height: 40px;
    border-radius: 400px;
    border: var(--Spacing-0, 2px) solid var(--Generic-White, #FFF);
}
.inviteusername {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.inviteuserdesc {
    color: var(--Neutral-500, #737373);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
}
.tablethLabel th {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    white-space: nowrap;
    text-align: start;
}
.tablebodySection {
    cursor: pointer;
    text-align: center;
}
.tdLabel {
    color: var(--Neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
} 
.viewLabelClick {
    cursor: pointer;
    color: #26DC4E;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
    margin: 0;
}
.referralURLModalHeader {
    border: none;
    padding: 6px 10px;
}
.referralURLTextformdiv {
    padding: 10px 30px;
}
.referralTextform {
    color: #40637D;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.15px;
    margin-bottom: 0;
 }
 .referralClickHereButton {
    padding: 0px 10px;
    color: #2F48FB !important;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    background: none;
    border: none;
    outline: none;
    opacity: 1 !important;
    margin-bottom: 0;
 }
 .referralClickHereButton {
    padding: 0px 10px;
    color: #2F48FB !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    background: none;
    border: none;
    outline: none;
    opacity: 1 !important;
    margin-bottom: 0;
 }
 .referralClickHereButton {
    padding: 0px 10px;
    color: #2F48FB !important;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    background: none;
    border: none;
    outline: none;
    opacity: 1 !important;
    margin-bottom: 0;
 }
 .referralClickHereButton:hover {
    color: #2F48FB !important;
    font-family: "Open Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
 }

 .referralStickySidebar {
    position: sticky;
    top: 85px; /* Keeps it at the top of the viewport while scrolling */
    height: 100vh;
}

.referralLeftComponentScroll {
    overflow-y: initial; /* Enable vertical scrolling */
    height: 100%; /* Ensures it fills the height */
    scrollbar-width: thin; /* Thin scrollbar for Firefox */
    scrollbar-width: none; /* Thin scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar on IE and Edge */
}

.referralLeftComponentScroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar on Chrome, Safari, Opera */
}


 

/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
  .responsiveDisplay {
    display: none;
  }
  .tabsPadding {
    padding: 5px;
  }
  .ItemCountdiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 2px;
  }
  .ActiviteIdDiv {
    padding: 15px;
    margin-bottom: 5em;   
  }
}
/* end of Mobile Screens */




/* Media Queries for Tablets and Larger Screens */
@media (min-width: 768px) and (max-width: 991px) {

}
/* end of Tablets and Larger Screens */