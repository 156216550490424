

.CartLoadContent {
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Required for the pseudo-element to be positioned correctly */
    z-index: 1; /* Ensure content is above the background */
}

.CartLoadContent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/public/Content/Images/Svgs/logo_whitebackground.svg') no-repeat center center; 
    background-size: 25%;
    opacity: 0.5;
    z-index: 10;
}
.CartModalDialog {
    float: inline-end;
    top: 2.2em;
    right: 12em;
    width: 25%;
}
.ModalCart .modal-content {
    border: none !important;
}
.CartModalDialog .modal-content {
    border: none !important;
}
.cartModalBody {
    border: none !important;
}
.cartImageview {
    width: 60px;
    height: 50px;
    border-radius: 6px;
}
.cartItemsDatadiv {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    min-height: 40vh;
    max-height: 55vh;
    margin-bottom: 2em;
}
.cartItemDetails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    contain: content;
}
.ModalbodyOverflow {
    max-height: 40vh;
    overflow: hidden;
    overflow-y: visible;
    scrollbar-width: thin;
}

.DeleteExperienceModal {
    color: #171717;
}

.subtotalLabel {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}

.SubtotalCountLabel {
    color: #FACF39;
    font-family: "Josefin Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 2.72px;
}
.quickviewbtn {
    padding: 2px 10px;
    border-radius: 24px;
    background: #1E2D39;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.cartpoptext {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f1f1f1;
}
.CartDeletebtn {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    cursor: pointer;
}
.CartDeletebtn:hover {
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;
    cursor: pointer;
}
.cartDeleteImage {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    cursor: pointer;
}
.cartDeleteImage:hover {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    cursor: pointer;
}

.Detailsdiv {
    padding: 1em 2em;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
    border-top: 4px solid #FACF39 !important;
    min-height: 50vh;
    max-height: 100vh;
}
.buyerNamelabel {
    padding: 2px 10px;
    border-radius: 24px;
    background: #E0E7FF;
}
.h2Namestyle {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 0.5em 0em 0em 0em;
}
.freeOrderslabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; 
    padding: 0.5em 0em;
    border-bottom: 1px solid #f1f1f1;
}
.h2NamestylePricelabel {
    color: #40637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 0.5em 0em;
    border-bottom: 1px solid #f1f1f1;
}
.cartCaptionlabel {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-overflow: ellipsis;
}
.cartPriceDatalabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cartTotalLabel {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-overflow: ellipsis;
}
.cartTotalValueTag {
    text-align: end;
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
}
.cartSellernamelabel {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
}

.subtotalspan {
    color: var(--Neutral-700, #404040);
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.verifiedlabel {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.checkoutBtn {
    padding: 10px 20px;
    border-radius: 6px;
    background: #2E637D;
    color: var(--Generic-White, #FFF);
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    
}
.PriceDetails {
    line-height: 2.5em;
}
.valueTag {
    text-align: end;
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 1rem;
}
.sellerNameTag {
    color: #0093ED;

}
.hrline {
    background: rgba(0, 0, 0, 0.05);
    color: #3D5A98;
}

.fafaDelete {
    width: 48px;
    height: 48px;
    padding: 8px;
    gap: 10px;
    border-radius: 24px;
    background: #EEF2FF;
}

.Background {
    background-color: #E6ECF3;;
}

.contentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.paymentSuccessMsg {
    align-self: stretch;
    color: #0EB65B;
    text-align: center;
    font-family: Josefin Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;
}
.paymentFailureMsg {
    align-self: stretch;
    color: #EE3636;
    text-align: center;
    font-family: Josefin Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;
}

.AprooveImg {
    width: 96px;
    height: 96px;
}

.viewportSetup {
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footerSec {
    height: 5vh;
}
.dashboardBtn {
    padding: 12px 20px;
    border-radius: 6px;
    background: #3D5A98;
    color: var(--generic-white, #FFF);
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.DeleteHeader {
    color: var(--neutral-900, #171717);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    align-self: stretch;
}
.DeleteLabel {
    align-self: stretch;
    color: var(--neutral-500, #737373);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.SubmitBtnStyle {
    padding: 12px 20px;
    border-radius: 6px;
    background: #2F48FB;
}
.NoItemBtn {
    padding: 12px 20px;
    border-radius: 6px;
    background: var(--generic-white, #FFF);
    color: var(--neutral-700, #404040);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.CartImageSetup {
    width: 100px;
    height: 60px;
    border: 2px solid rgba(16, 24, 40, 0.10);
    border-radius: 12px;
}
.CartSellerProfile {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.promoCodesSelectbox {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.promoCodesSelectbox input {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.promoCodeLabelDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    place-content: flex-start;
}

.promoCodeLabel {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.promoCodesEditBtn {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
}
.promoCodesEditBtn:hover {
    background: #FFF;
    border: none;
    color: #40637D;
}
.promoCodesInput {
    width: 100%;
    padding: 4px 10px;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.promoCodesSelect {
    color: #40637D;
    font-family: "Open Sans";
    opacity: 1;
    position: absolute;
    overflow-x: auto;
    overflow-y: auto;
    height: 50vh;
    scrollbar-width: thin;
}
.promoCodesItem {
    width: 100%;
    padding: 10px;
    color: #40637D;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.promoUserNamelabel {
    color: #40637D;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
}
.promoUserDesclabel {
    color: gray;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}




/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .responsiveDisplay {
      display: none;
    }
    .CartModalDialog {
        float: inline-end;
        top: 4em;
        right: 0em;
        width: 90%;
    }
    .CartImageSetup {
        width: 80px;
        height: 50px;
        border: none;
        border-radius: 10px;
    }
    .Detailsdiv {
        padding: 20px;
        margin-bottom: 1em;
    }

  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */