


.userProfileOuterBox {
    padding: 1em;
    display: flex;
    align-items: center;
    flex-flow: column;
    gap: 4px;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}
.userProfileInnerBox {
    background: rgba(47, 72, 251, 0.05);
    border-radius: 0em 0em 8em 8em;
}
.bottomBorder {
    margin-bottom: 1em;
    border-radius: 1em 1em 1em 1em;
}
.profileImage {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}
.profileName {
    color: #C62E65;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
}
.profileBio {
    color: var(--neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}

/* Filters Styles */
/* .filterBoxBody {
    border-radius: 0em 0em 12em 12em;
} */
.homefiltersrow {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: min-content;
    margin: 10px 0px;
}

.filtersHeader {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding-top: 5px;
    margin: 0;
}

.cateoryfiltersBox {    
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #2F485B;
    /* background: #2F485B url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFF" viewBox="0 0 16 16"><path d="M1.5 4.5a.5.5 0 0 1 .707 0L8 10.293l5.793-5.793a.5.5 0 0 1 .707.707l-6 6a.5.5 0 0 1-.707 0l-6-6a.5.5 0 0 1 0-.707z"/></svg>') no-repeat right 2px center; Adds a white dropdown arrow */
    border: 2px solid #2F485B;
    padding: 5px 10px;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    /* text-wrap-mode: wrap; */
}

.filtersBoxes {
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF;
    /* background: #FFFFFF url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%232F485B" viewBox="0 0 16 16"><path d="M1.5 4.5a.5.5 0 0 1 .707 0L8 10.293l5.793-5.793a.5.5 0 0 1 .707.707l-6 6a.5.5 0 0 1-.707 0l-6-6a.5.5 0 0 1 0-.707z"/></svg>') no-repeat right 2px center; Blue dropdown arrow */
    border: 2px solid #2F485B;
    padding: 5px 10px;
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    /* text-wrap-mode: wrap; */
}

.cateoryfiltersBox option {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}
.filtersBoxes option {
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 6px;
    border: #2F485B;
    border-radius: 10px;
}
.filtersBoxes option:disabled {
    color: lightgray;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;

}

.cateoryfiltersBox:hover {
    background-color: #3C5A71; /* Slightly lighter background on hover */
    border-color: #3C5A71; /* Match the border */
    outline: none; /* Remove default outline */
    cursor: pointer; /* Change cursor to pointer */
}

.filtersBoxes:focus {
    background-color: #FFFFFF; /* Slightly lighter background on hover */
    border-color: #3C5A71; /* Match the border */
    outline: none; /* Remove default outline */
    cursor: pointer; /* Change cursor to pointer */
}

.userSearchfilterInput {
    position: relative;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF;
    border: 2px solid #2F485B;
    padding: 5px 10px;
    color: #2F485B;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}
.clearButton {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #6c757d;
}

.UserDatalist {
    padding: 0;
    border: none;
    border-bottom: 1px dotted linen;
    margin-bottom: 5px;
    cursor: pointer;
}
.UserDetailsDiv {
    display: flex;
    align-items: normal;
    justify-content: flex-start;
    gap: 0.5em;
}
.UserIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}
.UserBiodataDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.UserNametag {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em; /* Adjust based on your font-size */
    max-height: 3em; /* line-height * 2 lines */
}
.UserDescriptionTag {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.nousersfoundFont {
    font-size: 14px;
    color: #1E2D39;
    color: #FF5370;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .clearFilterRow {
    display: flex;
    justify-content: flex-end; /* Align to the end */
  }  
.clearFiltersButton {
    text-decoration: underline;
    background: white;
    border: none;
    outline: none;
    font-size: 15px;
    color: #1E2D39;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    padding: 5px;
}
.clearFiltersButton:hover {
    background: none;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 600;  
    color: #1E2D39;
}