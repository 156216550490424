


.userProfileOuterBox {
    padding: 1em;
    display: flex;
    align-items: center;
    flex-flow: column;
    gap: 4px;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
}
.userProfileInnerBox {
    background: rgba(47, 72, 251, 0.05);
    border-radius: 0em 0em 12em 12em;
}

.profileImage {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}
.profileName {
    color: #C62E65;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0;
}
.profileBio {
    color: var(--neutral-500, #737373);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
}
.ProfileActionRow {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 6px;
    background: rgba(230, 236, 243, 0.40);
}
.ProfileButtons {
    background: none;
    border: none;
    color: #000;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.ProfileButtons:hover {
    background: none;
    border: none;
    outline: none;
    color: #FFF;
    font-size: 15px;
    font-weight: 800;
}
.ProfileActionRow:hover {
    background: #2F485B;
}

.ProfileRowActive {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 6px;
    background: #2F485B;
    box-shadow: 0px 6px 15px -10px rgba(47, 72, 251, 0.26);
}
.ProfileButtonActive {
    background: none;
    border: none;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}
.ProfileButtonActive:hover {
    background: none;
    border: none;
    outline: none;
    font-size: 15px;
    font-weight: 800;
}

.EngageActionsBox {
    background-color: white;
    padding: 0.5em 1.5em;
    /* height: 50vh; */
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
.SellerActionsBox {
    background-color: white;
    padding: 0.4em 1em;
    /* min-height: 40vh;
    height: 40vh; */
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.HomeActionsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0.5em 0.5em;
    /* min-height: 40vh;
    max-height: 40vh; */
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    
}
.ActionHeaderdiv {
    background-color: white;
    padding: 0 1.1em;
}
.ActionHeader {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
}

.ActionClickRows {
    background: var(--neutral-50, #FAFAFA);
    border-radius: 6px;
    padding: 10px;
}
.ActionClickRowsActive {
    border-radius: 6px;
    background: rgba(230, 236, 243, 0.40);
    padding: 10px;
}
.ActionClickBtns {
    border: none;
    background: none;
    box-shadow: none;
    text-align: start;
    color: var(--neutral-700, #000000);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    padding: 2px;
}
.ActionClickBtns:hover, .ActionClickRows:hover {
    color: var(--neutral-700, #FAFAFA);
}
.ActionClickBtnsActive {
    border: none;
    background: none;
    box-shadow: none;
    text-align: start;
    color: var(--neutral-50, #FAFAFA);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    padding: 2px;
}
.ActionClickRows:hover, .ActionClickRowsActive:hover {
    background-color: #2F485B;
    color: #000;
}

.ActionClickBadgeRow {
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: #E6ECF3;
    /* mix-blend-mode: multiply; */
}
.ActionClickTextActive {
    color: #E6ECF3;
    cursor: pointer;
}
.ActionClickBadgeRowActive {
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: var(--neutral-100, #FACF39);
    
}

.ActionClickBadge {
    color: var(--primary-600, #000);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.ActionClickBadgeActive {
    color: var(--primary-600, #000);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.ActionClickBadgeRowActive:hover, .ActionClickBadgeActive:hover {
    background: #2F485B;
    color: #000 !important;
}
.ActionClickBadgeRow:hover, .ActionClickBadge:hover {
    background: var(--neutral-100, #FACF39);
    color: #FFF !important;
}

.CountBox {
    border-radius: 12px;
    background: #40637D;
    box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
}
.BaseRightIcon {
    display: flex;
    justify-content: flex-end;
    color: #FFF;
    font-size: larger;
}
.TitleCard {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.TitleCardCount {
    color: #FACF39;
    font-family: "Josefin Sans";
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.IconSetup {
    width: 34px;
    height: 34px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 3px solid #FACF39;
    padding: 5px;
    
}
.IconSetImage {
    width: 18px;
    height: 18px;
}
.activeAction {
    background-color: #2F485B;
    color: #FFF;
}

.star {
    /* background-color: #2F485B; */
    color: #FACF39;
    cursor: pointer;
    font-size: 1.5em;
}

.footerSec {
    background-color: #E6ECF3;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.LinkClicks {
    color: #1E2D39;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;
}
.LinkClicks:hover {
    font-size: 15px;
    font-weight: 700;
    text-decoration: underline;
}

.followButtonStyles {
    border-radius: 24px;
    background: #FACF39;
    border-color: #FACF39;
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #2E637D;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

}

/* Messaging Styles */
.Messages {
    background-color: white;
    position: sticky;
    height: auto;
}
.MessagingProfile {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}
.Messages .card {
    border: none;
    box-shadow: none;
}
.Messages .list-group-item  {
  border: none;
}
.MsgSearch {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    border: 1px solid var(--neutral-200, #E5E5E5);
    background: var(--generic-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
    color: var(--neutral-400, #A3A3A3);
    /* Paragraph/Small/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.MsgCardbody {
    box-shadow: none;
}
.MsglistItem {
    padding: 0;
    border: none;
    border-bottom: 1px dotted linen;
}
.MsglistCardBody {
    padding: 0;
    max-height: 60vh;
    overflow: hidden;
    overflow-y: auto;
}
.MsgBtnClick {
    border: none;
    color: #040404;
    border-radius: 0em;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.MsgBtnClickActive {
    color: #2F485B;
    border-bottom: 0.2em solid #6366F1;
    font-weight: 600;
}
.MsgUserIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}
.liveUsername {
    color: var(--neutral-900, #171717);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.liveUsercontent {
    color: var(--neutral-500, #737373);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
/* Messaging Styles */

/* ChatboxPop */

.chatboxOverlay {
    mix-blend-mode: overlay;
    pointer-events: none;
}
.RightMessTitle{
    font-weight: 500;
}
.RightCont{
    background: white;
    border-radius: 0.5rem;
    height: 80vh;
    position: sticky;
    top: 90px;
}

.RightTitle_Cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.RightMessTitle{
    font-weight: 500;
}

.CloseBtnImg{
    height: 10px;
    width: 10px;
}

.Profileleft{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.ProfileTop{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ProfileImg{
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.PRImage {
    width: 95px;
    height: 60px;
    border-radius: 12px;
    margin-right: 1em;
    font-size: 12px;
}
.MoreBtn{
    height: 15px;
    width: 5px;
}

.circleoverlap{
    display: flex;
    position: relative;
}

.circleoverlap img {
    width: 25px;
    border-radius: 50%;
    border: solid white 2px; 
    margin-right: 2px;
    position: relative;
    background: teal;
  }
  
  .circleoverlapText {
    position: absolute;
    width: 100%;
    font-size: 0.75rem;
  }

  .ChatItemCont{
    padding: 1rem;
    display: flex;
    gap: 5px;
  }

  .ChatItemContRev{
    padding: 1rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
  }

  .ProfileChatName{
    font-size: 1rem;
    font-weight: 500;
  }

  .ProfileChatTime{
    margin-left: 0.5rem;
    font-size: 0.6rem;
  }

  .ProfileChatText{
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(134, 134, 134);
  }

.Exp_reactplayer > video{
    border-radius: 20px;
  }

.sendImgBtn{
    background: #714bff;
    border: solid #714bff;
    border-radius: 0.5rem;
    margin: 3px;
    width: calc(20% - 7px);
    height: 40px;
  }
.sendImg{
    height: 15px;
}

.sendInput{
    width: 80%;
    height: 40px;
    border-radius: 5px;
    border: solid lightgray;
    padding: 10px;
}

.sendTextCont{
    width: 100%;
    padding: 1rem;
    position: absolute;
    bottom: 1rem;
}

/* ChatboxPop */



/* FeedPost Styles */

.FeedBody {
    border-radius: 12px;
    background: #FFF;
    padding: 0.8rem;
}

.feedContainerdiv {
    position: relative;
    margin: 0 auto;
    box-shadow: 0px 8px 24px -3px rgba(16, 24, 40, 0.10);
}
 .feedThumbnail {
    border-radius: 12px !important;
    width: 100%;
    max-width: 100%;
    min-height: 500px;
    max-height: 650px;
 }
.feedContainerdiv .feedContent {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    background: transparent;
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
    border-radius: 0px 0px 12px 12px;
}
.feedContainerdiv .feedContentSide {
    position: absolute;
    bottom: 0.2rem;
    right: 1rem;
    background-color: transparent;
    color: #000;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
}
.feedContainerdiv .feedUserProfilediv {
    background: transparent;
}
.feedContainerdiv .feedContentdiv {
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.88);
    box-shadow: 0px 4px 4px 0px rgba(250, 207, 57, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}
.feedContentText {
    width: 80%;
    color: #FFF;
    text-shadow: 0px 4px 1000px rgba(0, 0, 0, 0.96);
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.feedIconwithCountsdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}
.feedUserImg {
    width: 60px;
    height: 60px;
    border-radius: 1000px;
    border: 4px solid #2F48FB;
    border-image: fill;
}
.feedIconImg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    flex-shrink: 0;
}

.feedIconFollowAdddiv {
    display: contents;
}
.feedIconFollowImg {
    width: 55px;
    height: 55px;
    border-radius: 400px;
    border: 5px solid #FFF;
    position: relative;
}
.feedIconFollowAddIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    margin-top: 3.2rem;
}
.feedUserContentFont {
    color: #1E2D39;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.CopyUrlBtn {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
}
.CopyUrlBtn:hover {
    background: none;
    border: none;
    outline: none;
}
/* FeedPost Styles */


/* Tooltip */

.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipContent {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 100%; /* Position to the right of the element */
    margin-left: 4px;
    padding: 0px 8px;
    background-color: #2F485B;
    color: #FFF;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    transform: translateY(-50%); /* Center vertically */
}

.tooltipContent::after {
    content: '';
    position: absolute;
    top: 50%; /* Center arrow vertically */
    left: -8px; /* Position arrow on the left side */
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #2F485B transparent transparent; /* Arrow pointing left */
}

.tooltipContainer:hover .tooltipContent,
.tooltipContainer:focus .tooltipContent,
.tooltipContainer:active .tooltipContent {
    opacity: 1;
    visibility: visible;
}

/* Tooltip */

/* commonSuccess styles */

.Background {
    background-color: #E6ECF3;;
  }
  .contentSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .viewportSetup {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .commonSuccessIcon {
    width: auto;
    height: auto;
    margin-bottom: 0.5em;
  }
  .commonSuccessStatus {
    color: #2E637D;
    font-family: "Josefin Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  .commonSuccessText {
    color: #4F46E5;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  
  
  /* commonSuccess page styles  */






/* General Media Query for Mobile Screens */
@media (max-width: 767px) {
  
    .footerSec {
        background-color: #E6ECF3;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        text-align: center;
    }
    .countBoxRowMargins {
        margin: 2px 5px;
    }
    .CountBox {
        border-radius: 12px;
        background: #40637D;
        box-shadow: 0px 8px 28px 0px rgba(16, 24, 40, 0.08);
    }
    .responsiveCountBody {
        padding: 8px 12px !important;
    }
    .BaseRightIcon {
        display: flex;
        justify-content: flex-end;
        color: #FFF;
        font-size: larger;
    }
    .TitleCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFF;
        font-family: "Open Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        
    }
    .TitleCardCount {
        color: #FACF39;
        font-family: "Josefin Sans";
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 10px;
    }
    .IconSetup {
        width: 20px;
        height: 20px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #FACF39;
        padding: 10px;    
    }
    .IconSetImage {
        width: 13px;
        height: 13px;
    }
    .activeAction {
        background-color: #2F485B;
        color: #FFF;
    }
    .star {
        color: #FACF39;
        cursor: pointer;
        font-size: 1.2em;
    }
  }
  /* end of Mobile Screens */
  
  
  
  
  
  /* Media Queries for Tablets and Larger Screens */
  @media (min-width: 768px) and (max-width: 991px) {

  }
  /* end of Tablets and Larger Screens */